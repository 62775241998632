import useStyles from '@core_modules/pages/401/components/style';

const ErrorContent = (props) => {
    const styles = useStyles();
    const { t } = props;

    return (
        <div className={styles.error}>
            <div className={styles.wrapper}>
                <img src="/assets/img/logo.svg" alt="logo" />
                <p className={styles.h2}>{t('common:error:401')}</p>
            </div>
            <style jsx global>
                {`
                    main {
                        border: none;
                        box-shadow: none;
                        margin-top: 90px;
                    }
                `}
            </style>
        </div>
    );
};

export default ErrorContent;
